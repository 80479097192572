import React from 'react'

const AwardsInfo = ({data}) => {
  var page = data.page
  return (
  <div className='p20'>
    <div className='w-70 m-100 text-center mt40'>
      <p className='m0 uppercase small'>(Awards)</p>
      <p className='m0 h1 mt20 mb40'>Jonson Lane</p>
      <div className='white-space light text-left mt20'> 
        {page.introduction}
      </div>
      <div className='awards light mt40'>
        <div className='collabs text-left'>
          {page.awards.map((collaborator, index) => {
            return ( 
              <div className='mb40'>
                <p className='ml40 m0 uppercase small mb20'>{collaborator.title}</p>
                <div className='text white-space' dangerouslySetInnerHTML={{ __html: collaborator.text }} />
              </div>
            )
          })}
        </div>
      </div>
    </div>
  </div>
  )
}

export default AwardsInfo
