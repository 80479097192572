import * as React from "react"
import Layout from "../components/layout"
import AwardsIntro from "../components/awardsIntro"
import Gallery from "../components/gallery" 
import Spacer from "../components/spacer"
import Seo from "../components/seo"
import { graphql } from 'gatsby'

const AwardsPage = ({data}) => {
  var page = data.page
  return (
  <Layout>
  <div className='flex m-wrap m-col-reverse'>
      <div className='w-50 m-100'>
        <AwardsIntro data={data} />
      </div>
      <div className='w-50 m-100'>
        <Gallery image={page.image} margin={false}/>
      </div>
    </div>
    <Spacer />
  </Layout>
  )
} 


export const Head = () => <Seo title="About" />

export default AwardsPage


export const query = graphql`
query AwardsQuery {
  page:datoCmsAwardsPage {
    introduction
    awards {
      title
      text
    }
    image {
      gatsbyImageData
    }
  }
}
`